<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="makerecruitment" style="width:100vw">
  <div id="a80361d5">
    <div id="ae7e925b">
      <div id="aba03b12">
        岗位名称
      </div>
      <el-input id="e27096b0" v-model="inp_e27096b0">
      </el-input>
    </div>
    <div id="af812cf2">
      <div id="a723222e">
        工作地点
      </div>
      <el-button id="fcecc527" @click="clickfcecc527()">
        选择地点
      </el-button>
    </div>
    <div id="ad6d2ebd">
      <div id="a8cb7e69">
        是否置顶
      </div>
      <el-radio-group id="ac01fa34" v-model="rdg_ac01fa34">
        <el-radio id="abf5f08f" label="是">
        </el-radio>
        <el-radio id="a5fe1bc0" label="否">
        </el-radio>
      </el-radio-group>
    </div>
    <div id="ab4177b2">
      <div id="a89ce348">
        状态
      </div>
      <el-select id="af8f4e7e" placeholder="使用" v-model="val_af8f4e7e">
        <el-option v-for="item in op_af8f4e7e" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div id="d7aba05c">
      <div id="a6ab1cd0">
        薪酬
      </div>
      <el-input id="a89fd416" v-model="inp_a89fd416">
      </el-input>
    </div>
    <div id="a65804e1">
      <div id="f22d6504">
        职位要求
      </div>
      <div id="bceb766d" ref="ref_bceb766d">
      </div>
    </div>
    <div id="a752a720">
      <el-button id="a511a651" @click="clicka511a651()">
        保存
      </el-button>
      <el-button id="d828519c" @click="clickd828519c()">
        取消
      </el-button>
    </div>
  </div>
  <div id="dlgfa99dcce2" v-show="vis_a99dcce2" @click.self="vis_a99dcce2=false">
    <div id="a99dcce2">
      <div id="d1729d11">
        工作地点
      </div>
      <div id="b8ed7bf4">
        <div id="a4690481">
          街道/写字楼
        </div>
        <el-input id="a6bff8dd" v-model="inp_a6bff8dd">
        </el-input>
      </div>
      <div id="a241bc36">
        <div id="a408cefd">
          门牌号
        </div>
        <el-input id="a7cb006a" v-model="inp_a7cb006a">
        </el-input>
      </div>
      <div id="a5c95ebe">
        <div id="b3035354">
          商圈
        </div>
        <el-select id="a619f663" placeholder="光谷" v-model="val_a619f663">
          <el-option v-for="item in op_a619f663" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <img src="../assets/img-aa5cd5e8.png" id="aa5cd5e8"/>
      <div id="ab1cb1cd">
        <el-button id="fa58dbb4" @click="clickfa58dbb4()">
          确认
        </el-button>
        <el-button id="afd44c15" @click="clickafd44c15()">
          取消
        </el-button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_editor from "wangeditor"
import dal_rest from "@/api/restful.js"
let edt_ref_bceb766d = null
export default{
  data(){
    return{
    inp_e27096b0:'',
    rdg_ac01fa34:'',
    rd_abf5f08f:'',
    rd_a5fe1bc0:'',
    op_af8f4e7e:[],
    val_af8f4e7e:'',
    inp_a89fd416:'',
    ref_bceb766d:'',
    vis_a99dcce2:false,
    inp_a6bff8dd:'',
    inp_a7cb006a:'',
    op_a619f663:[],
    val_a619f663:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
    edt_ref_bceb766d = new dal_editor(this.$refs.ref_bceb766d)
    edt_ref_bceb766d.config.uploadImgMaxLength = 1
    edt_ref_bceb766d.config.zIndex = 1
    edt_ref_bceb766d.config.height = 118
    edt_ref_bceb766d.config.customUploadImg = (resultFiles, insertImgFn) => {
      let fd = new FormData();
      fd.append("file", resultFiles[0]);
      dal_rest.post("", fd, {
        headers: { "Content-Type": "multipart/form-data","keepAlive":true },
        baseURL: "/myupload",
      })
      .then(
        (res) => {
          console.log("上传成功", res);
          let imgurl = res.content.url;
          insertImgFn(imgurl)
        },
        (err) => {
          console.log("上传失败", err);
          this.$message("上传失败");
        })
    }
    edt_ref_bceb766d.create()
    edt_ref_bceb766d.txt.html(this.$route.query.articleContent)
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clickfcecc527(){
      this.vis_a99dcce2 = true
    },
    async clicka511a651(){
      this.$router.push('recruitment')
    },
    async clickd828519c(){
      this.$router.push('recruitment')
    },
    async clickfa58dbb4(){
    },
    async clickafd44c15(){
    }
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a80361d5{
  width:1649px;
  position:relative;
  padding-bottom:348px;
}
#ae7e925b{
  width:588px;
  position:relative;
  padding-left:70px;
  padding-top:216px;
}
#aba03b12{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#aba03b12{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#ae7e925b .el-input{
  width:436px;
  margin-left:100px;
  display:inline-block;
  vertical-align: middle;
}
#af812cf2{
  width:294px;
  position:relative;
  margin-top:43px;
  padding-left:70px;
}
#a723222e{
  padding-top:6px;
  padding-bottom:6px;
  display:inline-block;
  vertical-align: middle;
}
#a723222e{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#fcecc527{
  width:142px;
  margin-left:100px;
  display:inline-block;
  vertical-align: middle;
}
#fcecc527{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#fcecc527{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#ad6d2ebd{
  width:328px;
  position:relative;
  margin-top:43px;
  padding-left:71px;
}
#a8cb7e69{
  display:inline-block;
  vertical-align: middle;
}
#a8cb7e69{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#ac01fa34{
  width:177px;
  margin-left:99px;
  display:inline-block;
  vertical-align: middle;
}
#ac01fa34{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#abf5f08f{
  width:41px;
  display:inline-block;
  vertical-align: middle;
}
#a5fe1bc0{
  width:41px;
  margin-left:95px;
  display:inline-block;
  vertical-align: middle;
}
#ab4177b2{
  width:588px;
  position:relative;
  margin-top:43px;
  padding-left:70px;
}
#a89ce348{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#a89ce348{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#ab4177b2 .el-select{
  width:436px;
  margin-left:126px;
}
#d7aba05c{
  width:588px;
  position:relative;
  margin-top:30px;
  padding-left:70px;
}
#a6ab1cd0{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#a6ab1cd0{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#d7aba05c .el-input{
  width:436px;
  margin-left:126px;
  display:inline-block;
  vertical-align: middle;
}
#a65804e1{
  width:952px;
  position:relative;
  margin-top:43px;
  padding-left:70px;
}
#f22d6504{
  padding-bottom:182px;
  display:inline-block;
  vertical-align: middle;
}
#f22d6504{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#bceb766d{
  width:800px;
  margin-left:100px;
  display:inline-block;
  vertical-align: middle;
}
#a752a720{
  width:344px;
  position:relative;
  margin-top:60px;
  padding-left:70px;
}
#a511a651{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#a511a651{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a511a651{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#d828519c{
  width:142px;
  margin-left:60px;
  display:inline-block;
  vertical-align: middle;
}
#d828519c{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#d828519c{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#dlgfa99dcce2{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#a99dcce2{
  position:absolute;
  top:248px;
  left:610px;
  width:700px;
  height:613px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#d1729d11{
  padding-top:30px;
}
#d1729d11{
  text-align:center;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#b8ed7bf4{
  width:545px;
  position:relative;
  margin-top:30px;
  padding-left:70px;
}
#a4690481{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#a4690481{
  text-align:right;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#b8ed7bf4 .el-input{
  width:443px;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#a241bc36{
  width:512px;
  position:relative;
  margin-top:30px;
  padding-left:103px;
}
#a408cefd{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#a408cefd{
  text-align:right;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a241bc36 .el-input{
  width:443px;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#a5c95ebe{
  width:499px;
  position:relative;
  margin-top:30px;
  padding-left:116px;
}
#b3035354{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#b3035354{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a5c95ebe .el-select{
  width:443px;
  margin-left:30px;
}
#aa5cd5e8{
  width:620px;
  height:188px;
  margin-top:30px;
  padding-left:40px;
}
#aa5cd5e8{
  vertical-align:middle;
}
#ab1cb1cd{
  width:404px;
  position:relative;
  margin-top:40px;
  padding-left:148px;
  padding-bottom:40px;
}
#fa58dbb4{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#fa58dbb4{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#fa58dbb4{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#afd44c15{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#afd44c15{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#afd44c15{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
